import moment from "moment-jalaali";
import timezonesData from '../assets/js/timezones'
import {addComma} from '../assets/js/functions'

export default {
  data () {
    return {
      showDescriptionActions: ['update', 'force_update', 'store', 'refresh_status']
    }
  },
  mixins: [timezonesData],
  methods: {
    getActivityLog (key) {
      return this.$t(`activityLogs.${key}`)
    },
    convertUtcToLocale (time, format = 'jYYYY jMM jDD HH:mm') {
      if (time) {
        const timezone = this.getTimezone(this.$store.state.setting.clubInfo.club_locale)
        return moment.utc(time).utcOffset(timezone).format(format)
      } else {
        return ''
      }

    },
    generateAccountNameLabel (account) {
      const seperator = ' > '
      if (account) {
        const accountList = account.split(seperator)
        let accountLabel = []
        if (accountList) {
          accountList.forEach(item => {
            accountLabel.push(this.$t(`accountancy.types.accountsLabel.${item}`))
          })
          return accountLabel.join(' - ')
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    getFieldChangeDescription (subject, field, oldValue, newValue) {
      const type = subject.split('.')[1] === 'store' ? 'store' : 'update'
      const name = subject.split('.')[0]
      field = field.replace(/.[0-9]+./g, '.')
      oldValue = this.customizeFieldValue(oldValue, field, name)
      newValue = this.customizeFieldValue(newValue, field, name)
      return this.$t(`activityLogs.changeFields.${type}.${name}.${field}`, {old: oldValue || '-', new: newValue || '-'})
    },
    customizeFieldValue (value, field, name) {
      let customValue = value

      // this condition is for set account name for cost accounts
      if (name === 'cost' && field === 'account') {
        customValue = this.generateAccountNameLabel(customValue)
      }

      // this condition is for set date format for value
      if (['date', 'invoice_date', 'reserved_date'].indexOf(field) > -1) {
        customValue = this.convertUtcToLocale(customValue, this.$localeValidator('moment.dateTime'))
      }

      // this condition is for set currency format for value
      if (['unit_price', 'final_price', 'price', 'sale_price', 'discount', 'tax', 'wage', 'vat', 'shipping', 'cancel_price', 'contract_price', 'reserve_price'].indexOf(field.split('.').at(-1)) > -1) {
        customValue = customValue ? `${addComma(customValue || '', true)} ${this.$locale.currency()}` : ''
      }

      // this condition is for set label for variable labels
      if (['status', 'type', 'payment_type', 'action_type', 'cancel_type', 'enabled'].indexOf(field) > -1 && value) {
        customValue = this.$t(`activityLogs.variablesLabel.${name}.${field}.${value}`)
      }

      return customValue
    }
  }
}
