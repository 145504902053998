<template>
  <div class="user-transactions " :class="[{'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'userTransactions'}]">
    <draggable-dynamic-table ref="userTransactions"
                             class="user-transactions"
                             :in-modal="userId > 0"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @load:more="getUserTransactions()"/>

    <button id="printUserTransactions" v-show="false" @click="handlePrintUserTransactions"></button>
  </div>
</template>

<script>
import axios from "axios";
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import setDocumentDescription from '../../../../mixins/setDocumentDescription'
import {getUser, getUserTransaction, printUserTransactions} from '@/http/requests/users/users'
import {getTimeFromServer, convertUtcToLocale, hideLoading, showLoading} from "../../../../assets/js/functions";

export default {
  name: 'userTransactions',
  components: {DraggableDynamicTable},
  metaInfo () {
    return {
      title: this.user ? this.$t('users.transactions.dynamicTitle', {name: `${this.user.name} ${this.user.family}`}) : this.$t('users.transactions.title')
    }
  },
  mixins: [setDocumentDescription],
  props: {
    userId: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      options: {
        id: 'userTransactions',
        rowKeyField: 'docNumber'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'users.transactions.table.header.balance',
          width: '130px',
          minWidth: 130,
          locked: true,
          // sortable: true
        },
        {
          field: 'price',
          i18n: 'users.transactions.table.header.price',
          width: '130px',
          minWidth: 130,
          locked: true,
          // sortable: true
          /*footer: {
            type: 'auto-sum',
            textType: 'price'
          }*/
        },
        {
          field: 'documentation',
          i18n: 'users.transactions.table.header.documentation',
          width: '120px',
          minWidth: 120,
          align: 'center',
          locked: true,
          // sortable: true
        },
        {
          field: 'date',
          i18n: 'users.transactions.table.header.date',
          align: 'center',
          width: '70px',
          minWidth: 70,
          locked: true,
          // sortable: true
        },
        {
          field: 'docNumber',
          i18n: 'users.transactions.table.header.docNumber',
          width: '80px',
          minWidth: 80,
          align: 'center',
          locked: true,
          // sortable: true
          /*footer: {
            type: 'auto-counter',
          }*/
        }
      ],
      user: null,
      data: [],
      filters: [],
      sorts: ['order[0]=created_at,desc'],
      page: 1,
      loadingTimer: 0,
      endedList: false,
      actions: {
        toolbar: [],
        leftToolbar: [
          {
            id: 'printTable',
            icon: 'PRINT',
            iconPack: 'useral'
          },
          {
            id: 'downloadTable',
            icon: 'DOWNLOAD',
            iconPack: 'useral'
          },
          {
            id: 'routeBackBTN',
            route: {name: this.$route.name === 'userTransaction' ? 'user' : 'customers'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  created () {
    if (this.$route.name === 'user') {
      this.actions.toolbar.push({
        id: 'editUser',
        icon: 'EDIT',
        iconPack: 'useral',
        color: 'warning',
        permission: 'user.update'
      })
    }

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
    }, 50)

    this.getUserInfo()

    this.getUserTransactions()
  },
  methods: {
    getUserInfo () {
      getUser(this.userId || this.$route.params.id).then(response => {
        this.user = response.data.data
        this.$store.dispatch('setPageTitle', this.$t('users.transactions.dynamicTitle', {name: `${this.user.name} ${this.user.family}`}))
      })
    },
    getUserTransactions () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.userTransactions && this.data.length === 0) {
            this.$refs.userTransactions.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.userTransactions && this.data.length > 0) {
            this.$refs.userTransactions.loadMoreStatus = 'Loading'
          }

          getUserTransaction(this.userId || this.$route.params.id, this.page, this.filters, this.sorts).then(response => {
            this.endedList = response.data.data.length === 0

            const userTransactions = response.data.data
            userTransactions.forEach((transaction) => {
              if (transaction.document.reference) transaction.document.reference.reference_type = transaction.document.reference_type

              this.data.push({
                docNumber: {
                  value: transaction.document.id,
                  route: {name: 'document', params: {id: transaction.document.id}},
                  target: '_blank'
                },
                date: {
                  value: convertUtcToLocale(transaction.document.date, this.$localeValidator('moment.dateTime')),
                  classes: this.$vs.rtl ? 'rtl' : 'ltr'
                },
                documentation: this.generateDocumentDescription(transaction.document, {hasRoute: true}),
                price: {
                  type: transaction.price !== 0 ? 'price' : 'number',
                  classes: transaction.price > 0 ? 'text-danger ltr' : transaction.price === 0 ? 'text-primary ltr' : 'text-success ltr',
                  value: transaction.price ? Math.abs(transaction.price) : 0
                },
                balance: {
                  type: transaction.balance !== 0 ? 'price' : 'number',
                  classes: transaction.balance > 0 ? 'text-danger ltr' : transaction.balance === 0 ? 'text-primary ltr' : 'text-success ltr',
                  value: transaction.balance ? Math.abs(transaction.balance) : 0
                }
              })
            })

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('docNumber')

              this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
            }

            if (this.$refs.userTransactions) this.$refs.userTransactions.loadMoreStatus = ''
          }).catch((error) => {
            if (this.$refs.userTransactions && !axios.isCancel(error)) this.$refs.userTransactions.loadMoreStatus = 'Danger'
          })
        }
      }, 500)
    },

    handlePrintUserTransactions () {
      showLoading()

      printUserTransactions(this.userId || this.$route.params.id, this.filters, this.sorts).then(response => {
        hideLoading()

        const data = response.data

        let time = getTimeFromServer()
        let fileURL = window.URL.createObjectURL(new Blob([data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `userTransactionList-${ time }.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
        .catch((error) => {
          hideLoading()

          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('users.transactions.notifications.print.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-transactions {
  padding: 0;

  &:not(.fixed-draggable-dynamic-table-wrapper-height) {
    height: 100%;
  }
}
</style>
