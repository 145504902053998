<template>
  <div class="user-max-debt"
       :class="{'vx-card main-box top-zero-radius p-2': userId === 0, 'no-box-shadow in-modal': $route.name !== 'userMaxDebt'}">
    <div class="user-max-debt-fields">
      <custom-price-input :label="$t('users.user.labels.maxDebt')"
                          :is-empty="true"
                          :has-comma="true"
                          :auto-focus="true"
                          :classes="{'w-full': true}"
                          v-model="maxDebt"
                          @pressEnter="sendData"/>
    </div>

    <button v-show="false" id="setUserMaxDebtBTN" @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomPriceInput from "../../../../components/customInput/customPriceInput";
  import {editUser, getUser} from '../../../../http/requests/users/users'
  import {checkUserPermissions, customParseFloat} from "../../../../assets/js/functions";

  export default {
    name: 'setUserMaxDebt',
    components: {CustomPriceInput},
    metaInfo () {
      return {
        title: this.user.fullName ? this.$t('users.user.maxDebt.dynamicTitle', {name: this.user.fullName}) : this.$t('users.user.maxDebt.title')
      }
    },
    props: {
      userId: {
        type: Number,
        default: 0
      },
      userMaxDebt: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        user: {},
        maxDebt: {},
        actions: {
          toolbar: [
            {
              id: 'setUserMaxDebtBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'userMaxDebt' ? 'user' : 'customers'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    created() {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      }, 50)
      if (this.userId === 0) {
        this.getUser()
      } else {
        this.maxDebt = {
          value: this.userMaxDebt,
          isValid: true
        }
        this.$store.dispatch('setPageTitle', this.$t('users.user.maxDebt.title'))
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      customParseFloat,
      getUser() {
        getUser(this.$route.params.id).then((response) => {
          const user = response.data.data
          this.user.fullName = `${user.name} ${user.family}`
          this.maxDebt = {
            value: user.max_debt || '0',
            isValid: true
          }

          this.$store.dispatch('setPageTitle', this.$t('users.user.maxDebt.dynamicTitle', {name: this.user.fullName}))
        })
      },
      sendData() {
        if (this.checkUserPermissions('user.set_max_debt')) {
          const user = {
            max_debt: customParseFloat(this.maxDebt.value.toString().replaceAll(',', '')) || 0
          }
          editUser(this.userId || this.$route.params.id, user).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('users.user.notifications.edit.success'),
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400,
              color: 'success'
            })

            if (this.$route.name === 'userMaxDebt') {
              this.$router.back()
            } else {
              this.$emit('setMaxDebt')
            }
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('users.user.notifications.edit.error'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'danger'
              })
            }
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('users.user.notifications.noAccessMaxDebt'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-max-debt {
    .user-max-debt-fields {
      max-width: 400px;
    }
  }
</style>
